import { Profile } from '@repo/common/entities';
import {
// Cloud,
// CreditCard,
// Github,
// Keyboard,
// LifeBuoy,
LogOut
// Mail,
// MessageSquare,
// Plus,
// PlusCircle,
// Settings,
// User,
// UserPlus,
// Users,
} from 'lucide-react';
import { useRouter } from 'next/navigation';
import React, { useCallback } from 'react';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '@/components/ui/dropdown-menu';
import { useSignOut, useUser } from '@/hooks/useUser';
import { cn } from '@/lib/utils';
import { Avatar, AvatarFallback, AvatarImage } from '../ui/avatar';
import { NavigationMenuLink } from '../ui/navigation-menu';
import { Skeleton } from '../ui/skeleton';

// import * as DropdownMenu from '@radix-ui/react-dropdown-menu'

export const UserAvatar: React.FC<{
  className?: string;
  user: Profile | null;
}> = ({
  className,
  user
}) => {
  if (!user) return null;
  const getFallbackWord = () => {
    if (user.username) return user.username[0];
    if (user.full_name) return user.full_name[0];
    return '';
  };
  return <Avatar className={className} data-sentry-element="Avatar" data-sentry-component="UserAvatar" data-sentry-source-file="UserAvatar.tsx">
      <AvatarImage src={user.avatar_url ?? undefined} alt={user.full_name ?? undefined} data-sentry-element="AvatarImage" data-sentry-source-file="UserAvatar.tsx" />
      <AvatarFallback data-sentry-element="AvatarFallback" data-sentry-source-file="UserAvatar.tsx">{getFallbackWord()}</AvatarFallback>
    </Avatar>;
};
export const UserInfo: React.FC<{
  className?: string;
  size?: 'sm' | 'md' | 'lg';
  profile: Profile;
  avatarOnly?: boolean;
}> = ({
  className,
  size = 'md',
  profile,
  avatarOnly
}) => {
  if (!profile) return null;
  return <div className={cn('flex items-center gap-2', size === 'sm' ? 'text-sm gap-1' : size === 'md' ? 'text-base' : 'text-lg', className)} data-uid={profile.id} data-sentry-component="UserInfo" data-sentry-source-file="UserAvatar.tsx">
      <UserAvatar user={profile} className={cn(size === 'sm' ? 'w-6 h-6' : size === 'md' ? 'w-8 h-8' : 'w-10 h-10')} data-sentry-element="UserAvatar" data-sentry-source-file="UserAvatar.tsx" />
      {!avatarOnly && <div>{profile.username || profile.full_name}</div>}
    </div>;
};
const ListItem = React.forwardRef<React.ElementRef<'a'>, React.ComponentPropsWithoutRef<'a'>>(({
  className,
  title,
  children,
  ...props
}, ref) => {
  return <li>
        <NavigationMenuLink asChild>
          <a ref={ref} className={cn('block select-none space-y-1 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground', className)} {...props}>
            <div className="text-sm font-medium leading-none">{title}</div>
            <p className="line-clamp-2 text-sm leading-snug text-muted-foreground">{children}</p>
          </a>
        </NavigationMenuLink>
      </li>;
});
ListItem.displayName = 'ListItem';
export const UserBar: React.FC<{
  className?: string;
  enableGetStarted?: boolean;
}> = ({
  className,
  enableGetStarted
}) => {
  const {
    user,
    loading: userLoading,
    hasLogin
  } = useUser();
  const signOut = useSignOut();
  const router = useRouter();
  const handleSignIn = useCallback(() => {
    router.push('/login');
  }, [router]);
  return <div className={cn('flex items-center gap-2', className)} data-sentry-component="UserBar" data-sentry-source-file="UserAvatar.tsx">
      {userLoading ? <Skeleton className="h-10 w-10 rounded-full" /> : hasLogin && user && user.profile ?
    // <NavigationMenu>
    //   <NavigationMenuList>
    //     <NavigationMenuItem>
    //       <NavigationMenuPrimitive.Trigger className="flex items-center justify-center">
    //           <UserInfo profile={user.profile} avatarOnly size="lg" />
    //       </NavigationMenuPrimitive.Trigger>
    //       <NavigationMenuContent>
    //         {/* <NavigationMenuLink> */}
    //           <ListItem title="Logout"></ListItem>
    //         {/* </NavigationMenuLink> */}
    //       </NavigationMenuContent>
    //     </NavigationMenuItem>
    //   </NavigationMenuList>
    // </NavigationMenu>
    <DropdownMenu>
          <DropdownMenuTrigger>
            <UserInfo profile={user.profile} avatarOnly size="lg" />
          </DropdownMenuTrigger>
          <DropdownMenuContent align="end">
            <DropdownMenuLabel>My Account</DropdownMenuLabel>
            <DropdownMenuSeparator />
            {/* <DropdownMenuItem>Profile</DropdownMenuItem>
             <DropdownMenuItem>Billing</DropdownMenuItem>
             <DropdownMenuItem>Team</DropdownMenuItem>
             <DropdownMenuItem>Subscription</DropdownMenuItem> */}
            {/* <DropdownMenuSeparator /> */}
            <DropdownMenuItem color="red" onClick={signOut}>
              <LogOut />
              <span>Log out</span>
              {/* <DropdownMenuShortcut>⇧⌘Q</DropdownMenuShortcut> */}
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu> : <>
          <button className="px-3 py-1.5 sm:px-4 sm:py-2 text-xs sm:text-sm md:text-base rounded-full border border-black transition-all duration-300 ease-in-out" onClick={handleSignIn}>
            Sign in
          </button>
          {enableGetStarted && <button className="px-3 py-1.5 sm:px-4 sm:py-2 text-xs sm:text-sm md:text-base rounded-full bg-black text-white transition-all duration-300 ease-in-out" onClick={handleSignIn}>
              Get started
            </button>}
        </>}
    </div>;
};